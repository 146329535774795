import {
    Box,
    Breadcrumb,
    BreadcrumbItem,
    BreadcrumbLink,
    Button,
    Center,
    Grid,
    GridItem,
    Heading,
    Image,
    Link,
    Menu,
    MenuButton,
    MenuItem,
    MenuItemOption,
    MenuList,
    MenuOptionGroup,
    Modal,
    ModalBody,
    ModalCloseButton,
    ModalContent,
    ModalOverlay,
    Spacer,
    Stack,
    Tag,
    Text,
    VStack,
    Wrap,
    WrapItem,
    useColorModeValue,
} from '@chakra-ui/react';
import Avatar from 'boring-avatars';
import i18next, { t } from 'i18next';
import { useState } from 'react';
import { FaItchIo } from 'react-icons/fa';
import {
    RiArrowDropDownLine,
    RiExternalLinkLine,
    RiFacebookCircleFill,
    RiGlobalLine,
    RiInstagramFill,
    RiSteamFill,
    RiTwitterXLine,
} from 'react-icons/ri';
import { IGame } from '../../data/games';

type GameProps = {
    game: IGame;
    version: number;
};

const iconMap = {
    'steampowered.com': <RiSteamFill size={20} />,
    'instagram.com': <RiInstagramFill size={20} />,
    'facebook.com': <RiFacebookCircleFill size={20} />,
    'itch.io': <FaItchIo size={20} />,
    'x.com': <RiTwitterXLine size={20} />,
};

function renderIcon(url: string) {
    const lowercasedUrl = url.toLowerCase();
    for (const [key, icon] of Object.entries(iconMap)) {
        if (lowercasedUrl.includes(key)) {
            return icon;
        }
    }
    return <RiGlobalLine size={20} />;
}

type Photo = {
    id: number;
    src: string;
    alt: string;
};

export function Game({ game, version }: GameProps) {
    // Correct way to access the second media item, if it exists
    const secondMediaId =
        game.releases[version].media && game.releases[version].media!.length > 1
            ? game.releases[version].media![0].id
            : undefined;
    let highlighted = false;

    const [selectedPhoto, setSelectedPhoto] = useState<Photo | null>(null);
    const [isModalOpen, setIsModalOpen] = useState(false);

    const openModal = (photo: Photo) => {
        setSelectedPhoto(photo);
        setIsModalOpen(true);
    };

    const closeModal = () => {
        setSelectedPhoto(null);
        setIsModalOpen(false);
    };

    return (
        <Box>
            <Box position="relative" w="100%" minH={10}>
                {secondMediaId && (
                    <>
                        <Image
                            src={secondMediaId}
                            alt="heading-game"
                            w="100%"
                            h={{ base: 36, sm: 36, md: 56, lg: 72 }}
                            objectFit="cover"
                            objectPosition={'center center'}
                            sx={{
                                boxShadow: useColorModeValue(
                                    '2px 2px 10px rgba(128, 128, 128, 0.7)',
                                    '2px 2px 10px rgba(44, 48, 68, 0.7)',
                                ),
                            }}
                        />
                        <Box
                            position="absolute"
                            top={0}
                            left={0}
                            w="100%"
                            h="100%"
                            backgroundColor="rgba(0, 0, 0, 0.4)"
                        />
                    </>
                )}

                <Breadcrumb position="absolute" top={7} left={10} color={secondMediaId ? 'white' : undefined}>
                    <BreadcrumbItem>
                        <BreadcrumbLink href="/">Garrigue Games</BreadcrumbLink>
                    </BreadcrumbItem>

                    <BreadcrumbItem>
                        <BreadcrumbLink href="/games">{t('menuButtons.games')}</BreadcrumbLink>
                    </BreadcrumbItem>

                    <BreadcrumbItem fontWeight={'semibold'} isCurrentPage>
                        <BreadcrumbLink href={'games/' + game.releases[version].id}>{game.name?.iv}</BreadcrumbLink>
                    </BreadcrumbItem>
                </Breadcrumb>
            </Box>

            <VStack maxW={1536} p={10} alignItems={'left'}>
                <Stack direction={{ base: 'column', md: 'row' }} align={'baseline'} gap={0}>
                    <VStack alignItems={'left'} gap={0}>
                        <Heading
                            fontSize="4xl"
                            fontWeight={'semibold'}
                            color={useColorModeValue('brand.500', 'brand.400')}
                        >
                            {game.name?.iv}
                        </Heading>
                        <Text
                            paddingBottom={4}
                            fontSize="sm"
                            fontFamily="body"
                            fontWeight="regular"
                            fontStyle="italic"
                            color={useColorModeValue('gray.400', 'gray.500')}
                        >
                            Version {game.releases[version].id}
                        </Text>

                        <Wrap spacing="10px" marginBottom={5}>
                            {game.releases[version].tags?.map((tag) => (
                                <WrapItem key={tag!.iv}>
                                    <Center>
                                        <Tag
                                            size="md"
                                            fontSize="xs"
                                            variant={'transparent'}
                                            border={'1px'}
                                            backgroundColor={'transparent'}
                                            borderColor={useColorModeValue('gray.300', 'gray.700')}
                                        >
                                            <Text fontSize="xs">{i18next.language == 'cs' ? tag!.cs : tag!.iv}</Text>
                                        </Tag>
                                    </Center>
                                </WrapItem>
                            ))}
                        </Wrap>
                    </VStack>
                    <Spacer />
                    <Stack direction={{ base: 'row', md: 'column' }} paddingBottom={3} wrap={'wrap'}>
                        <Menu>
                            <MenuButton
                                w={40}
                                as={Button}
                                size={'sm'}
                                variant="transparent"
                                fontSize="sm"
                                border={'1px'}
                                backgroundColor={'transparent'}
                                borderColor={useColorModeValue('gray.300', 'gray.700')}
                                rightIcon={<RiArrowDropDownLine size={20} />}
                                iconSpacing="2px"
                                paddingX={3}
                                _hover={{
                                    bg: useColorModeValue('gray.200', 'gray.800'),
                                }}
                                _active={{
                                    bg: useColorModeValue('gray.200', 'gray.800'),
                                }}
                            >
                                <Text isTruncated>Version {game.releases[version].id}</Text>
                            </MenuButton>
                            <MenuList>
                                <MenuOptionGroup defaultValue={game.releases[version].id} type="radio">
                                    {game.releases.map((release) => (
                                        <MenuItemOption as="a" href={release.id} key={release.id} value={release.id}>
                                            Version {release.id}
                                        </MenuItemOption>
                                    ))}
                                </MenuOptionGroup>
                            </MenuList>
                        </Menu>
                        <Menu>
                            <MenuButton
                                w={40}
                                as={Button}
                                size={'sm'}
                                variant="solid"
                                fontSize="sm"
                                textColor={'gray.50'}
                                fontWeight={'semibold'}
                                backgroundColor={useColorModeValue('brand.500', 'brand.400')}
                                rightIcon={<RiArrowDropDownLine size={22} />}
                                _hover={{
                                    bg: useColorModeValue('brand.400', 'brand.500'),
                                }}
                                _active={{
                                    bg: useColorModeValue('brand.400', 'brand.500'),
                                }}
                            >
                                Download game
                            </MenuButton>
                            <MenuList>
                                <Link href={'/game/Omnibullet Demo.zip'} download="OmnibulletDemo">
                                    <MenuItem>Windows</MenuItem>
                                </Link>
                            </MenuList>
                        </Menu>
                    </Stack>
                </Stack>

                <Heading fontSize="xl" fontWeight={'medium'}>
                    {t('games.authors')}
                </Heading>
                <Text marginBottom={5} fontSize="md">
                    <Wrap spacingX={10} spacingY={5} marginTop={2}>
                        {game.releases[version].authors?.map((author) => (
                            <WrapItem key={author.name!.iv}>
                                <Center>
                                    <Avatar
                                        size={40}
                                        name={author.name!.iv}
                                        variant="marble"
                                        colors={['#CA68C8', '#EE81A7', '#FC944A', '#FFD770', '#C0ECCD']}
                                    />
                                    <Text key={author.name!.iv} marginLeft={3} fontSize={'sm'}>
                                        {i18next.language == 'cs' ? author.name!.cs : author.name!.iv}
                                    </Text>
                                </Center>
                            </WrapItem>
                        ))}
                    </Wrap>
                </Text>
                <Heading fontSize="xl" fontWeight={'medium'}>
                    {t('games.about')}
                </Heading>
                <Text fontSize="sm" maxW={1536} marginBottom={5}>
                    {i18next.language == 'cs'
                        ? game.releases[version].description?.cs
                        : game.releases[version].description?.iv}
                </Text>

                <Grid
                    marginBottom={5}
                    templateRows={{ base: 'auto', md: 'repeat(2, 1fr)' }}
                    templateColumns={{ base: '1', md: 'repeat(4, 1fr)' }}
                    gap={5}
                >
                    {game.releases[version].media?.map((image, index) => {
                        const isHighlighted = !highlighted;
                        if (isHighlighted) highlighted = true;

                        return (
                            <GridItem key={image.id} rowSpan={isHighlighted ? 2 : 1} colSpan={isHighlighted ? 2 : 1}>
                                <Image
                                    src={image.id}
                                    objectFit="cover"
                                    w="100%"
                                    h="100%"
                                    borderRadius="md"
                                    sx={{
                                        boxShadow: useColorModeValue(
                                            '2px 2px 10px rgba(128, 128, 128, 0.7)',
                                            '2px 2px 10px rgba(44, 48, 68, 0.7)',
                                        ),
                                    }}
                                    cursor={'pointer'}
                                    onClick={() => openModal({ id: index, src: image.id, alt: 'Description' + index })}
                                />
                            </GridItem>
                        );
                    })}
                </Grid>

                <Heading fontSize="xl" fontWeight={'medium'}>
                    {t('games.gamedetails')}
                </Heading>
                <Grid
                    w="auto"
                    autoRows="auto"
                    templateColumns={{ base: '1fr 1fr', md: '1fr 7fr' }}
                    gap={2}
                    marginBottom={5}
                >
                    <GridItem>
                        <Text
                            fontSize="sm"
                            as="span"
                            color={useColorModeValue('gray.500', 'gray.400')}
                            fontWeight="bold"
                        >
                            {t('games.genre') + ':'}
                        </Text>
                    </GridItem>
                    <GridItem>
                        {game.releases[version].genre?.map((genre, index) => (
                            <Text key={genre!.iv} fontSize="sm" as="span">
                                {i18next.language === 'cs' ? genre!.cs : genre!.iv}
                                {index < game.releases[version].genre.length - 1 && ' — '}
                            </Text>
                        ))}
                    </GridItem>

                    {game.type!.iv == 'Video Game' && (
                        <>
                            <GridItem>
                                <Text
                                    fontSize="sm"
                                    as="span"
                                    color={useColorModeValue('gray.500', 'gray.400')}
                                    fontWeight="bold"
                                >
                                    {t('games.workson') + ':'}
                                </Text>
                            </GridItem>
                            <GridItem>
                                <Text fontSize="sm" as="span">
                                    {game.releases[version].downloads![0].platform?.iv}
                                </Text>
                            </GridItem>
                        </>
                    )}

                    <GridItem>
                        <Text
                            fontSize="sm"
                            as="span"
                            color={useColorModeValue('gray.500', 'gray.400')}
                            fontWeight="bold"
                        >
                            {t('games.releasedate') + ':'}
                        </Text>
                    </GridItem>
                    <GridItem>
                        <Text fontSize="sm" as="span">
                            {game.releases[version].releasedOn.toLocaleDateString()}
                        </Text>
                    </GridItem>

                    {game.type!.iv == 'Video Game' && (
                        <>
                            <GridItem>
                                <Text
                                    fontSize="sm"
                                    as="span"
                                    color={useColorModeValue('gray.500', 'gray.400')}
                                    fontWeight="bold"
                                >
                                    {t('games.size') + ':'}
                                </Text>
                            </GridItem>
                            <GridItem>
                                <Text fontSize="sm" as="span">
                                    {game.releases[version].downloads![0].size + ' B'}
                                </Text>
                            </GridItem>
                        </>
                    )}

                    <GridItem>
                        <Text
                            fontSize="sm"
                            as="span"
                            color={useColorModeValue('gray.500', 'gray.400')}
                            fontWeight="bold"
                        >
                            {t('games.language') + ':'}
                        </Text>
                    </GridItem>
                    <GridItem>
                        {game.releases[version].language?.map((language, index) => (
                            <Text key={language!.iv} fontSize="sm" as="span">
                                {i18next.language === 'cs' ? language!.cs : language!.iv}
                                {index < game.releases[version].language.length - 1 && ', '}
                            </Text>
                        ))}
                    </GridItem>

                    <GridItem>
                        <Text
                            fontSize="sm"
                            color={useColorModeValue('gray.500', 'gray.400')}
                            fontWeight="bold"
                            as="span"
                        >
                            {t('games.license') + ':'}{' '}
                        </Text>
                    </GridItem>
                    <GridItem>
                        <Text fontSize="sm" as="span">
                            {i18next.language === 'cs'
                                ? game.releases[version].license!.name!.cs
                                : game.releases[version].license!.name!.iv}
                        </Text>
                    </GridItem>
                </Grid>
                <Heading fontSize="lg" fontWeight={'medium'}>
                    {t('games.extras')}
                </Heading>
                <Button
                    variant="link"
                    size="sm"
                    fontSize="sm"
                    fontWeight={'medium'}
                    rightIcon={<RiExternalLinkLine />}
                    color={useColorModeValue('gray.900', 'gray.100')}
                    width={'fit-content'}
                >
                    Devlogs
                </Button>
                <Button
                    variant="link"
                    size="sm"
                    fontSize="sm"
                    fontWeight={'medium'}
                    rightIcon={<RiExternalLinkLine />}
                    color={useColorModeValue('gray.900', 'gray.100')}
                    width={'fit-content'}
                    marginBottom={5}
                >
                    Game Design Documents
                </Button>

                <Heading fontSize="lg" fontWeight={'medium'}>
                    {t('games.links')}
                </Heading>

                {game.releases[version].links?.map((link) => (
                    <Link key={link.url} href={link.url} target="_blank" rel="noopener noreferrer">
                        <Button
                            key={link.url}
                            variant="link"
                            size="sm"
                            fontSize="sm"
                            fontWeight={'medium'}
                            color={useColorModeValue('gray.900', 'gray.100')}
                            width={'fit-content'}
                            leftIcon={renderIcon(link.url)}
                        >
                            {i18next.language === 'cs' ? link.label!.cs : link.label!.iv}
                        </Button>
                    </Link>
                ))}
            </VStack>

            <Modal isOpen={isModalOpen} onClose={closeModal} size={'3xl'} isCentered>
                <ModalOverlay />
                <ModalContent m={0} borderRadius="none">
                    <ModalCloseButton
                        size="lg"
                        _hover={{ color: 'black' }}
                        zIndex="1"
                        position="absolute"
                        top="4"
                        right="4"
                    />
                    <ModalBody p={0}>
                        {selectedPhoto && (
                            <Image
                                key={selectedPhoto?.id}
                                src={selectedPhoto?.src}
                                alt={selectedPhoto?.alt}
                                boxSize="100%"
                                width="100vw"
                                height="100%"
                                objectFit="cover"
                                objectPosition="center"
                                shadow="md"
                                cursor="pointer"
                            />
                        )}
                    </ModalBody>
                </ModalContent>
            </Modal>
        </Box>
    );
}
