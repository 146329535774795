import { Divider, Heading, Stack, Text, useColorModeValue } from '@chakra-ui/react';
import { Link } from 'react-router-dom';

type EventButtonProps = {
    link: string;
    name: string;
    date: string;
    location: string;
    isInternal?: boolean;
};

export const EventButton = ({ link, name, date, location, isInternal = false }: EventButtonProps) => {
    return (
        <Link to={link} target={isInternal ? '_self' : '_blank'} rel="noopener noreferrer">
            <Stack direction="row" h="100px" w="200px" alignItems={'center'}>
                <Divider
                    orientation="vertical"
                    borderWidth={'2px'}
                    borderColor={useColorModeValue('brand.500', 'brand.400')}
                    opacity={1}
                    marginRight={3}
                />
                <Stack direction="column">
                    <Text>{date}</Text>
                    <Heading fontSize="1xl" fontWeight={'semibold'} color={useColorModeValue('brand.500', 'brand.400')}>
                        {name}
                    </Heading>
                    <Text>{location}</Text>
                </Stack>
            </Stack>
        </Link>
    );
};
