import {
    Box,
    Breadcrumb,
    BreadcrumbItem,
    BreadcrumbLink,
    Divider,
    Heading,
    Image,
    Stack,
    Text,
    VStack,
    useColorModeValue,
} from '@chakra-ui/react';
import { t } from 'i18next';
import { Link, useLocation } from 'react-router-dom';
import { useReloadVar } from '../../hooks/useReload';

export function Courses() {
    const { reload, value } = useReloadVar();
    const location = useLocation();

    return (
        <Box>
            <Box position="relative" w="100%">
                <Image
                    src="/images/headings/heading2.jpg"
                    alt="heading-about"
                    w="100%"
                    h={{ base: 36, sm: 36, md: 56, lg: 72 }}
                    objectFit="cover"
                    objectPosition={'center center'}
                    shadow={'md'}
                />

                {/* original top={7} */}
                <Breadcrumb position="absolute" top={7} left={10} color={'white'}>
                    <BreadcrumbItem>
                        <BreadcrumbLink href="/">Garrigue Games</BreadcrumbLink>
                    </BreadcrumbItem>

                    <BreadcrumbItem fontWeight={'semibold'} isCurrentPage>
                        <BreadcrumbLink href="/courses">{t('menuButtons.courses')}</BreadcrumbLink>
                    </BreadcrumbItem>
                </Breadcrumb>
            </Box>

            <VStack maxW={1536} p={10} alignItems={'left'}>
                <Heading fontSize="3xl" fontWeight={'semibold'} color={useColorModeValue('brand.500', 'brand.400')}>
                    {t('courses.title')}
                </Heading>
                <Text marginBottom={5} fontSize="md">
                    {t('courses.paragraph1')}
                </Text>
                <Stack direction="column" gap={'20px'}>
                    <Link to="https://is.muni.cz/predmet/fi/PV255" target="_blank" rel="noopener noreferrer">
                        <Stack direction="row" h="30px" alignItems={'center'} gap={'20px'}>
                            <Divider
                                orientation="vertical"
                                borderWidth={'2px'}
                                borderColor={useColorModeValue('brand.500', 'brand.400')}
                                opacity={1}
                            />

                            <Text fontSize="md" fontWeight={'semibold'}>
                                FI:PV255 Game Development I
                            </Text>
                        </Stack>
                    </Link>
                    <Link to="https://is.muni.cz/predmet/fi/PV266" target="_blank" rel="noopener noreferrer">
                        <Stack direction="row" h="30px" alignItems={'center'} gap={'20px'}>
                            <Divider
                                orientation="vertical"
                                borderWidth={'2px'}
                                borderColor={useColorModeValue('brand.500', 'brand.400')}
                                opacity={1}
                            />

                            <Text fontSize="md" fontWeight={'semibold'}>
                                FI:PV266 Game Development II
                            </Text>
                        </Stack>
                    </Link>
                    <Link to="https://is.muni.cz/predmet/fi/PA215" target="_blank" rel="noopener noreferrer">
                        <Stack direction="row" h="30px" alignItems={'center'} gap={'20px'}>
                            <Divider
                                orientation="vertical"
                                borderWidth={'2px'}
                                borderColor={useColorModeValue('brand.500', 'brand.400')}
                                opacity={1}
                            />

                            <Text fontSize="md" fontWeight={'semibold'}>
                                FI:PA215 Game Design I
                            </Text>
                        </Stack>
                    </Link>
                    <Link to="https://is.muni.cz/predmet/fi/PA216" target="_blank" rel="noopener noreferrer">
                        <Stack direction="row" h="30px" alignItems={'center'} gap={'20px'}>
                            <Divider
                                orientation="vertical"
                                borderWidth={'2px'}
                                borderColor={useColorModeValue('brand.500', 'brand.400')}
                                opacity={1}
                            />

                            <Text fontSize="md" fontWeight={'semibold'}>
                                FI:PA216 Game Design II
                            </Text>
                        </Stack>
                    </Link>
                    <Link to={location.pathname + '/dungeon'} target="_self" rel="noopener noreferrer">
                        <Stack direction="row" h="30px" alignItems={'center'} gap={'20px'}>
                            <Divider
                                orientation="vertical"
                                borderWidth={'2px'}
                                borderColor={useColorModeValue('brand.500', 'brand.400')}
                                opacity={1}
                            />

                            <Text fontSize="md" fontWeight={'semibold'}>
                                FI:VV078 Game Development Dungeon
                            </Text>
                        </Stack>
                    </Link>
                </Stack>
            </VStack>
        </Box>
    );
}
