import {
    Box,
    Breadcrumb,
    BreadcrumbItem,
    BreadcrumbLink,
    Card,
    CardBody,
    Center,
    Flex,
    HStack,
    Heading,
    Highlight,
    Image,
    Input,
    Link,
    Stack,
    Tag,
    Text,
    VStack,
    useColorModeValue,
} from '@chakra-ui/react';
import i18next, { t } from 'i18next';

import { useState } from 'react';
import { RiCircleFill, RiGameFill } from 'react-icons/ri';
import { useLocation } from 'react-router-dom';
import { IGame, IGameRelease, MOCKUP_GAMES } from '../../data/games';
import { useReloadVar } from '../../hooks/useReload';
import { ContactUs } from '../utils/ContactUs';

export function GamesGallery() {
    const { reload, value } = useReloadVar();
    const [selectedSort, setSelectedSort] = useState<string>('recent');
    const [search, setSearch] = useState<string>('');

    const handleTagClick = (tag: string) => {
        setSelectedSort((prev) => (prev === tag ? prev : tag));
    };

    type comparator = (a: { game: IGame; release: IGameRelease }, b: { game: IGame; release: IGameRelease }) => number;

    const sortBy: Record<string, { label: string; comparator: comparator }> = {
        recent: {
            label: t('games.recent'),
            comparator: (a, b) => b.release.releasedOn.getTime() - a.release.releasedOn.getTime(),
        },
        oldest: {
            label: t('games.oldest'),
            comparator: (a, b) => a.release.releasedOn.getTime() - b.release.releasedOn.getTime(),
        },
        az: {
            label: t('games.az'),
            comparator: (a, b) =>
                (i18next.language == 'cs' ? a.game.name!.cs : a.game.name!.iv).localeCompare(
                    i18next.language == 'cs' ? b.game.name!.cs : b.game.name!.iv,
                ),
        },
        za: {
            label: t('games.za'),
            comparator: (a, b) =>
                (i18next.language == 'cs' ? b.game.name!.cs : b.game.name!.iv).localeCompare(
                    i18next.language == 'cs' ? a.game.name!.cs : a.game.name!.iv,
                ),
        },
    };

    const location = useLocation();

    const gameItems = MOCKUP_GAMES.map((game) => game.releases.map((release) => ({ game, release }))).flat();

    return (
        <Box>
            <Box position="relative" w="100%">
                <Breadcrumb position="relative" top={7} left={10} color={useColorModeValue('black', 'white')}>
                    <BreadcrumbItem>
                        <BreadcrumbLink href="/">Garrigue Games</BreadcrumbLink>
                    </BreadcrumbItem>

                    <BreadcrumbItem fontWeight={'semibold'} isCurrentPage>
                        <BreadcrumbLink href="/games">{t('menuButtons.games')}</BreadcrumbLink>
                    </BreadcrumbItem>
                </Breadcrumb>
            </Box>

            <VStack maxW={1536} p={10} paddingTop={16} alignItems={'left'}>
                <Heading fontSize="3xl" fontWeight={'semibold'} color={useColorModeValue('brand.500', 'brand.400')}>
                    {t('games.title')}
                </Heading>

                <Heading
                    fontFamily={'body'}
                    fontSize="md"
                    fontWeight={'semibold'}
                    color={useColorModeValue('gray.900', 'gray.50')}
                >
                    {t('games.search')}
                </Heading>

                <Input
                    placeholder={t('games.searchfor')}
                    _placeholder={{ opacity: 1, color: useColorModeValue('gray.400', 'gray.600') }}
                    borderColor={useColorModeValue('gray.300', 'gray.700')}
                    value={search}
                    onChange={(e) => setSearch(e.target.value)}
                    focusBorderColor="brand.500"
                />

                <Flex direction="row" paddingTop={3} columnGap={14} rowGap={5} wrap={'wrap'}>
                    <Box>
                        <Heading
                            fontFamily={'body'}
                            fontSize="md"
                            fontWeight={'semibold'}
                            color={useColorModeValue('gray.900', 'gray.50')}
                        >
                            {t('games.sort')}
                        </Heading>
                        <HStack spacing={2} paddingTop={2} wrap={'wrap'}>
                            {Object.entries(sortBy).map(([key, tag]) => (
                                <Tag
                                    key={key}
                                    size="md"
                                    fontSize="xs"
                                    variant={selectedSort === key ? 'solid' : 'transparent'}
                                    border={selectedSort === key ? '0px' : '1px'}
                                    backgroundColor={
                                        selectedSort === key
                                            ? useColorModeValue('brand.500', 'brand.400')
                                            : 'transparent'
                                    }
                                    borderColor={
                                        selectedSort === key ? 'transparent' : useColorModeValue('gray.300', 'gray.700')
                                    }
                                    cursor="pointer"
                                    onClick={() => handleTagClick(key)}
                                >
                                    {tag.label}
                                </Tag>
                            ))}
                        </HStack>
                    </Box>
                </Flex>

                <Flex paddingTop={5} gap={5} wrap="wrap">
                    {gameItems
                        .filter(({ game, release }) => {
                            if (search.length > 0) {
                                const searcher = (obj: any) => {
                                    if (typeof obj === 'string') {
                                        return obj.toLowerCase().includes(search.toLowerCase());
                                    } else if (typeof obj === 'object') {
                                        return Object.values(obj).some(searcher);
                                    } else if (Array.isArray(obj)) {
                                        return obj.some(searcher);
                                    } else {
                                        return false;
                                    }
                                };

                                return searcher(game) || searcher(release);
                            }
                            return true;
                        })
                        .sort(sortBy[selectedSort].comparator)
                        .map(({ game, release }, i) => (
                            <Link key={i} href={location.pathname + '/' + release.id}>
                                <Card maxW="xs" variant="unstyled" backgroundColor="transparent">
                                    <CardBody>
                                        <Center
                                            w={{ base: 60, md: 80 }}
                                            h={{ base: 36, md: 56 }}
                                            borderRadius="md"
                                            sx={{
                                                boxShadow: useColorModeValue(
                                                    '2px 2px 10px rgba(128, 128, 128, 0.7)',
                                                    '2px 2px 10px rgba(44, 48, 68, 0.7)',
                                                ),
                                            }}
                                            background={useColorModeValue('gray.300', 'gray.700')}
                                            overflow="hidden"
                                        >
                                            {release.media && release.media!.length > 1 ? (
                                                <Image
                                                    src={release.media![1].id}
                                                    alt="card-game"
                                                    width="100%"
                                                    height="100%"
                                                    objectFit="cover"
                                                />
                                            ) : (
                                                <>
                                                    <RiGameFill size={40} />
                                                    <RiCircleFill size={10} />
                                                    <span style={{ width: 5 }} />
                                                    <RiCircleFill size={10} />
                                                    <span style={{ width: 5 }} />
                                                    <RiCircleFill size={10} />
                                                </>
                                            )}
                                        </Center>

                                        <Stack mt="4" spacing="1">
                                            <Heading size="md">
                                                <Highlight query={search} styles={{ bg: 'brand.400', color: 'white' }}>
                                                    {i18next.language == 'cs' ? game.name!.cs : game.name!.iv}
                                                </Highlight>
                                            </Heading>
                                            {release.authors && (
                                                <Box>
                                                    <Text fontSize={'sm'} as={'span'}>
                                                        <Highlight
                                                            query={search}
                                                            styles={{ bg: 'brand.400', color: 'white' }}
                                                        >
                                                            {release.authors
                                                                .map((author, index2) =>
                                                                    i18next.language == 'cs'
                                                                        ? author.name!.cs
                                                                        : author.name!.iv,
                                                                )
                                                                .join(', ')}
                                                        </Highlight>
                                                    </Text>
                                                </Box>
                                            )}
                                        </Stack>
                                    </CardBody>
                                </Card>
                            </Link>
                        ))}
                </Flex>

                <Box paddingTop={8}>
                    <ContactUs />
                </Box>
            </VStack>
        </Box>
    );
}
