/**
 * A mockup of the data that should be used for th games.muni.cz front-end.
 */

import { HRIB, localizedString } from '../schemas/generic';

/**
 * Primary game abstraction for use in both lists and details.
 */
export interface IGame {
    name: localizedString;
    releases: Array<IGameRelease>;
    type: localizedString; // will be enum
}

/**
 * A game can have multiple releases (e.g., one in the GameDev I course and one in GameDev II). This is one of them.
 */
export interface IGameRelease {
    id: HRIB;
    releasedOn: Date;
    tags: Array<localizedString>;
    license: IGameLicense;
    language: Array<localizedString>; // will be enum
    genre: Array<localizedString>;

    description?: localizedString;
    authors?: Array<IGameAuthor>;
    downloads?: Array<IGameDownload>;
    links?: Array<IGameLink>;
    media?: Array<IGameMedia>;
}

export interface IGameDownload {
    platform: localizedString;
    size: number;
}

export interface IGameLink {
    url: string;
    label: localizedString;
}

export interface IGameAuthor {
    name: localizedString;
    id?: HRIB;
}

export interface IGameLicense {
    name: localizedString;
    url?: string;
}

export interface IGameMedia {
    id: HRIB;
    kind: GameMediaKind;
    alt?: localizedString;
}

export enum GameMediaKind {
    IMAGE = 'image',
    VIDEO = 'video',
    DOCUMENT = 'document',
}

export interface IGameEvent {
    date: Date;
    place?: string;
    name: localizedString;
    url?: string;
}

export interface IGameCourse {
    name: localizedString;
    url?: string;
    tags: Array<localizedString>;
    description: localizedString;
    faculty: localizedString;
}

export interface IGameCommunity {
    name: localizedString;
    url?: string;
}

// TODO, add the defition for pc games/board games, add course
export const MOCKUP_GAMES: Array<IGame> = [
    {
        name: {
            iv: 'Omnibullet',
            cs: 'Omnibullet',
        },
        type: { iv: 'Video Game', cs: 'Počítačová hra' },
        releases: [
            {
                id: 'm_omnibulle',
                tags: [
                    {
                        iv: 'Automation',
                        cs: 'Automatizace',
                    },
                    {
                        iv: 'Tower defense',
                        cs: 'Vežobrana :P',
                    },
                    {
                        iv: 'Puzzle',
                        cs: 'Puzzle',
                    },
                    {
                        iv: 'Logic',
                        cs: 'Logická',
                    },
                    {
                        iv: 'Difficult',
                        cs: 'Obtížné',
                    },
                ],
                license: {
                    name: {
                        iv: 'School work',
                        cs: 'Školní dílo',
                    },
                },
                language: [
                    { iv: 'English', cs: 'Angličtina' },
                    { iv: 'Czech', cs: 'Čeština' },
                ],
                genre: [
                    {
                        iv: 'Puzzle',
                        cs: 'Puzzle',
                    },
                    { iv: 'Logic', cs: 'Logické' },
                    { iv: 'Building', cs: 'Stavěcí' },
                ],
                releasedOn: new Date(Date.parse('2023-11-22')),
                authors: [
                    // {
                    //     id: 'm_arbitrary', //this is not good
                    //     name: {
                    //         iv: 'Arbitrary Combination',
                    //     },
                    // },
                    {
                        name: {
                            iv: 'General Kenobi',
                            cs: 'Generál Kenobi',
                        },
                    },
                    {
                        name: {
                            iv: 'Your mom',
                            cs: 'Tvoje máma',
                        },
                    },
                ],
                description: {
                    iv: 'Are you ready to charge up your career? Does your current desk job bore you? Did you always want to build sophisticated tower systems to shoot batteries with energy bullets? To streamline its battery factory production, our company is seeking a Production Line Architect. Your primary task? Make sure the charging process runs smoothly.',
                    cs: 'Jste připraveni posunout svou kariéru na vyšší úroveň? Nudí vás vaše současná kancelářská práce? Vždy jste chtěli stavět sofistikované věžové systémy, které střílí energetické střely na baterie? Naše společnost hledá Architekta výrobní linky, aby zefektivnila výrobu v továrně na baterie. Vaším hlavním úkolem bude zajistit, aby nabíjecí proces probíhal hladce.',
                },
                links: [
                    {
                        label: {
                            iv: 'Steam',
                            cs: 'Steam',
                        },
                        url: 'https://store.steampowered.com/app/2128590/Omnibullet/',
                    },
                    {
                        label: {
                            iv: 'X/Twitter',
                            cs: 'X/Twitter',
                        },
                        url: 'https://x.com/omnibullet_game',
                    },
                    {
                        label: {
                            iv: 'Instagram',
                            cs: 'Instagram',
                        },
                        url: 'https://www.instagram.com/omnibullet_game/',
                    },
                    {
                        label: {
                            iv: 'Facebook',
                            cs: 'Facebook',
                        },
                        url: 'https://www.facebook.com/omnibullet_game/',
                    },
                    {
                        label: {
                            iv: 'Itch.io',
                            cs: 'Itch.io',
                        },
                        url: 'https://arbitrary-combination.itch.io/omnibullet',
                    },
                    {
                        label: {
                            iv: 'Omnibullet',
                            cs: 'Omnibullet',
                        },
                        url: 'https://omnibullet.cz/',
                    },
                ],
                downloads: [
                    {
                        platform: {
                            iv: 'Windows',
                        },
                        size: (177 * 2) << 30,
                    },
                ],
                media: [
                    // {
                    //     id: 'videos/omnibullet_video.mp4',
                    //     kind: GameMediaKind.VIDEO,
                    //     alt: {
                    //         iv: 'Trailer',
                    //     },
                    // },
                    // {
                    //     id: '/images/omnibullet/omnibullet_1.jpg',
                    //     kind: GameMediaKind.IMAGE,
                    // },
                    // {
                    //     id: '/images/omnibullet/omnibullet_2.jpg',
                    //     kind: GameMediaKind.IMAGE,
                    // },
                    // {
                    //     id: '/images/omnibullet/omnibullet_3.jpg',
                    //     kind: GameMediaKind.IMAGE,
                    // },
                    // {
                    //     id: '/images/omnibullet/omnibullet_4.jpg',
                    //     kind: GameMediaKind.IMAGE,
                    // },
                    // {
                    //     id: '/images/omnibullet/omnibullet_5.jpg',
                    //     kind: GameMediaKind.IMAGE,
                    // },
                ],
            },
            {
                id: 'v2_m_omnibulle',
                tags: [
                    {
                        iv: 'Automation',
                        cs: 'Automatizace',
                    },
                    {
                        iv: 'Tower defense',
                        cs: 'Vežobrana :P',
                    },
                    {
                        iv: 'Puzzle',
                        cs: 'Puzzle',
                    },
                    {
                        iv: 'Logic',
                        cs: 'Logická',
                    },
                    {
                        iv: 'Difficult',
                        cs: 'Obtížné',
                    },
                    {
                        iv: '3D',
                        cs: '3D',
                    },
                ],
                license: {
                    name: {
                        iv: 'School work',
                        cs: 'Školní dílo',
                    },
                },
                language: [
                    { iv: 'English', cs: 'Angličtina' },
                    { iv: 'Czech', cs: 'Čeština' },
                ],
                genre: [
                    {
                        iv: 'Puzzle',
                        cs: 'Puzzle',
                    },
                    { iv: 'Logic', cs: 'Logické' },
                    { iv: 'Building', cs: 'Stavěcí' },
                ],
                releasedOn: new Date(Date.parse('2024-02-19')),
                authors: [
                    // {
                    //     id: 'm_arbitrary', //this is not good
                    //     name: {
                    //         iv: 'Arbitrary Combination',
                    //     },
                    // },
                    {
                        name: {
                            iv: 'Vlastimil Blazek',
                            cs: 'Vlastimil Blažek',
                        },
                    },
                    {
                        name: {
                            iv: 'Vendula Nemcova',
                            cs: 'Vendula Němcová',
                        },
                    },
                    {
                        name: {
                            iv: 'Jan Polak',
                            cs: 'Jan Polák',
                        },
                    },
                ],
                description: {
                    iv: 'THIS IS SECOND RELEASE Are you ready to charge up your career? Does your current desk job bore you? Did you always want to build sophisticated tower systems to shoot batteries with energy bullets? To streamline its battery factory production, our company is seeking a Production Line Architect. Your primary task? Make sure the charging process runs smoothly.',
                    cs: 'TOTO JE DRUHÉ VYDÁNÍ Jste připraveni posunout svou kariéru na vyšší úroveň? Nudí vás vaše současná kancelářská práce? Vždy jste chtěli stavět sofistikované věžové systémy, které střílí energetické střely na baterie? Naše společnost hledá Architekta výrobní linky, aby zefektivnila výrobu v továrně na baterie. Vaším hlavním úkolem bude zajistit, aby nabíjecí proces probíhal hladce.',
                },
                links: [
                    {
                        label: {
                            iv: 'Steam',
                            cs: 'Steam',
                        },
                        url: 'https://store.steampowered.com/app/2128590/Omnibullet/',
                    },
                    {
                        label: {
                            iv: 'X/Twitter',
                            cs: 'X/Twitter',
                        },
                        url: 'https://x.com/omnibullet_game',
                    },
                ],
                downloads: [
                    {
                        platform: {
                            iv: 'Windows',
                        },
                        size: (177 * 2) << 30,
                    },
                ],
                media: [
                    // {
                    //     id: 'videos/omnibullet_video.mp4',
                    //     kind: GameMediaKind.VIDEO,
                    //     alt: {
                    //         iv: 'Trailer',
                    //     },
                    // },
                    {
                        id: '/images/omnibullet/omnibullet_1.jpg',
                        kind: GameMediaKind.IMAGE,
                    },
                    {
                        id: '/images/omnibullet/omnibullet_2.jpg',
                        kind: GameMediaKind.IMAGE,
                    },
                    {
                        id: '/images/omnibullet/omnibullet_3.jpg',
                        kind: GameMediaKind.IMAGE,
                    },
                    {
                        id: '/images/omnibullet/omnibullet_4.jpg',
                        kind: GameMediaKind.IMAGE,
                    },
                    {
                        id: '/images/omnibullet/omnibullet_5.jpg',
                        kind: GameMediaKind.IMAGE,
                    },
                    {
                        id: '/images/omnibullet/omnibullet_6.jpg',
                        kind: GameMediaKind.IMAGE,
                    },
                    {
                        id: '/images/omnibullet/omnibullet_7.jpg',
                        kind: GameMediaKind.IMAGE,
                    },
                ],
            },
        ],
    },

    {
        name: {
            iv: 'Hexabe',
            cs: 'Hexabe',
        },
        type: { iv: 'Board game', cs: 'Stolní hra' },
        releases: [
            {
                id: 'm_hexabe',
                tags: [
                    {
                        iv: 'Hexagons',
                        cs: 'Šestiúhelníky',
                    },
                    {
                        iv: 'Pattern Matching',
                        cs: 'Hledání vzorů',
                    },
                    {
                        iv: 'Strategy',
                        cs: 'Strategie',
                    },
                ],
                license: {
                    name: {
                        iv: 'School work',
                        cs: 'Školní dílo',
                    },
                },
                language: [
                    { iv: 'English', cs: 'Angličtina' },
                    { iv: 'Czech', cs: 'Čeština' },
                ],
                genre: [
                    { iv: 'Strategy', cs: 'Strategie' },
                    { iv: 'Pattern Matching', cs: 'Hledání vzorů' },
                ],
                releasedOn: new Date(Date.parse('2023-06-19')),
                authors: [
                    {
                        name: {
                            iv: 'Eva Kuhejdova',
                            cs: 'Eva Kuhejdová',
                        },
                    },
                    {
                        name: {
                            iv: 'Hana Tokarova',
                            cs: 'Hana Tokárová',
                        },
                    },
                    {
                        name: {
                            iv: 'Jonas Rosecky',
                            cs: 'Jonáš Rosecký',
                        },
                    },
                ],
                description: {
                    iv: 'A strategy building game with hexagonal tiles, where the players compete to be the first to place all their goal tiles. They do this by placing their color tiles and searching for the patterns on the ever-expanding game board.',
                    cs: 'Strategická budovatelská hra s hexagonovými dílky, ve které se hráči snaží co nejrychleji postavit vlastní cíle. Toho dosáhnou pokládáním barevných dílů a hledáním vzorů na neustále se měnícím herním plánu.',
                },
                links: [
                    {
                        label: {
                            iv: 'Instagram',
                            cs: 'Instagram',
                        },
                        url: 'https://www.instagram.com/hexabe_game/',
                    },
                    {
                        label: {
                            iv: 'Web',
                            cs: 'Web',
                        },
                        url: 'https://hexabe.cz/',
                    },
                ],
                media: [
                    {
                        id: '/images/hexabe/hexabe_1.jpg',
                        kind: GameMediaKind.IMAGE,
                    },
                    {
                        id: '/images/hexabe/hexabe_2.jpg',
                        kind: GameMediaKind.IMAGE,
                    },
                    {
                        id: '/images/hexabe/hexabe_3.jpg',
                        kind: GameMediaKind.IMAGE,
                    },
                ],
            },
        ],
    },
];

export class Client {
    _games = new (class {
        get getAll() {
            return MOCKUP_GAMES;
        }
    })();

    get games() {
        return this._games;
    }
}
