import {
    Box,
    Breadcrumb,
    BreadcrumbItem,
    BreadcrumbLink,
    Flex,
    Heading,
    Image,
    Text,
    VStack,
    useColorModeValue,
} from '@chakra-ui/react';
import { t } from 'i18next';

import { Link } from 'react-router-dom';
import { useReloadVar } from '../../hooks/useReload';
import { EventButton } from '../utils/EventButton';

export function Events() {
    const { reload, value } = useReloadVar();

    return (
        <Box>
            <Box position="relative" w="100%">
                <Image
                    src="/images/headings/heading2.jpg"
                    alt="heading-events"
                    w="100%"
                    h={{ base: 36, sm: 36, md: 56, lg: 72 }}
                    objectFit="cover"
                    objectPosition={'center center'}
                    shadow={'md'}
                />

                <Breadcrumb position="absolute" top={7} left={10} color={'white'}>
                    <BreadcrumbItem>
                        <BreadcrumbLink href="/">Garrigue Games</BreadcrumbLink>
                    </BreadcrumbItem>

                    <BreadcrumbItem fontWeight={'semibold'} isCurrentPage>
                        <BreadcrumbLink href="/events">{t('menuButtons.events')}</BreadcrumbLink>
                    </BreadcrumbItem>
                </Breadcrumb>
            </Box>

            <VStack maxW={1536} p={10} alignItems={'left'}>
                <Heading fontSize="3xl" fontWeight={'semibold'} color={useColorModeValue('brand.500', 'brand.400')}>
                    {t('events.title')}
                </Heading>
                <Text marginBottom={5} fontSize="md">
                    {t('events.part1')} -{' '}
                    <Text textStyle="link" as="span">
                        <Link to="https://visiongame.cz/udalosti/" target="_blank" rel="noopener noreferrer">
                            web Visiongame
                        </Link>
                    </Text>
                    . {t('events.part2')} -{' '}
                    <Text as="span" textStyle="link">
                        <Link to="/ecosystem">{t('menuButtons.community')}</Link>
                    </Text>
                    .
                </Text>
                <Heading fontSize="2xl" fontWeight={'medium'} color={useColorModeValue('brand.500', 'brand.400')}>
                    {t('events.upcomming')}
                </Heading>

                <Heading as="i" fontSize="xl" fontWeight={'semibold'} paddingTop={'10px'} paddingBottom={1}>
                    {t('events.september')}
                </Heading>
                <Flex gap="12" wrap="wrap" paddingBottom={3}>
                    <EventButton
                        link="/courses/dungeon"
                        name="VV078 Game Development Dungeon"
                        date="31.8.-7.9.2024"
                        location="Dolní Morava"
                        isInternal
                    />
                </Flex>

                <Heading as="i" fontSize="xl" fontWeight={'semibold'} paddingTop={'10px'} paddingBottom={1}>
                    {t('events.october')}
                </Heading>
                <Flex gap="12" wrap="wrap" paddingBottom={3}>
                    <EventButton
                        link="https://game-connect.cz/"
                        name="GameDev Connect '24"
                        date="19.10.-20.10.2024"
                        location="Brněnské Výstaviště"
                    />
                </Flex>

                <Heading
                    color={useColorModeValue('brand.500', 'brand.400')}
                    fontSize="2xl"
                    fontWeight={'medium'}
                    paddingTop={'10px'}
                >
                    {2025}
                </Heading>

                <Heading as="i" fontSize="xl" fontWeight={'semibold'} paddingBottom={1}>
                    {t('events.june')}
                </Heading>
                <Flex gap="12" wrap="wrap">
                    <EventButton
                        link="https://www.game-access.com/conference/"
                        name="Game Access Conference '25"
                        date="6.6.-7.6.2025"
                        location="Brněnské Výstaviště"
                    />
                </Flex>
            </VStack>
        </Box>
    );
}
