import {
    Box,
    Breadcrumb,
    BreadcrumbItem,
    BreadcrumbLink,
    Heading,
    Image,
    Text,
    VStack,
    useColorModeValue,
} from '@chakra-ui/react';
import { t } from 'i18next';
import { useReloadVar } from '../../hooks/useReload';
import { ContactUs } from '../utils/ContactUs';

export function About() {
    const { reload, value } = useReloadVar();

    return (
        <Box>
            <Box position="relative" w="100%">
                <Image
                    src="/images/headings/heading2.jpg"
                    alt="heading-about"
                    w="100%"
                    h={{ base: 36, sm: 36, md: 56, lg: 72 }}
                    objectFit="cover"
                    objectPosition={'center center'}
                    shadow={'md'}
                />

                {/* original top={7} */}
                <Breadcrumb position="absolute" top={7} left={10} color={'white'}>
                    <BreadcrumbItem>
                        <BreadcrumbLink href="/">Garrigue Games</BreadcrumbLink>
                    </BreadcrumbItem>

                    <BreadcrumbItem fontWeight={'semibold'} isCurrentPage>
                        <BreadcrumbLink href="/about">{t('menuButtons.about')}</BreadcrumbLink>
                    </BreadcrumbItem>
                </Breadcrumb>
            </Box>

            <VStack maxW={1536} p={10} alignItems={'left'}>
                <Heading fontSize="3xl" fontWeight={'semibold'} color={useColorModeValue('brand.500', 'brand.400')}>
                    {t('about.title')}
                </Heading>
                <Text marginBottom={5} fontSize="md">
                    {t('about.paragraph1')}
                </Text>
                <Heading fontSize="2xl" fontWeight={'medium'} color={useColorModeValue('brand.500', 'brand.400')}>
                    {t('about.heading1')}
                </Heading>
                <Text marginBottom={5} fontSize="sm">
                    {t('about.paragraph2')}
                </Text>
                <Heading fontSize="2xl" fontWeight={'medium'} color={useColorModeValue('brand.500', 'brand.400')}>
                    {t('about.heading2')}
                </Heading>
                <Text marginBottom={5} fontSize="sm">
                    {t('about.paragraph3')}
                </Text>
                <Heading fontSize="2xl" fontWeight={'medium'} color={useColorModeValue('brand.500', 'brand.400')}>
                    {t('about.heading3')}
                </Heading>
                <Text marginBottom={5} fontSize="sm">
                    {t('about.paragraph4')}
                </Text>
                <Heading fontSize="xl" fontWeight={'medium'} color={useColorModeValue('brand.500', 'brand.400')}>
                    {t('about.heading4')}
                </Heading>
                <Text marginBottom={5} fontSize="sm">
                    {t('about.paragraph5')}
                </Text>

                <ContactUs />
            </VStack>
        </Box>
    );
}
